@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .loading-clip {
    clip-path: inset(0 1.4ch 0 0);
    animation: animationLoading 1s steps(4) infinite;
  }

  .gradient-border {
    border: solid 1px transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
}
