/* This CSS is mostly copied from react-calendar's default css. */
.calendarWrapper :global(.react-calendar) {
  width: 350px;
}

.calendarWrapper :global(.react-calendar button:enabled:hover) {
  cursor: pointer;
}

.calendarWrapper :global(.react-calendar__navigation) {
  display: flex;
  margin-bottom: 1em;
  height: 44px;
}

.calendarWrapper :global(.react-calendar__navigation button) {
  min-width: 44px;
}

.calendarWrapper :global(.react-calendar__navigation button:disabled) {
  visibility: hidden;
}

.calendarWrapper :global(.react-calendar__navigation button:enabled:hover) {
  background-color: #e6e6e6;
}

.calendarWrapper :global(.react-calendar__month-view__weekdays) {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.calendarWrapper :global(.react-calendar__month-view__weekdays__weekday) {
  padding: 0.5em;
}

.calendarWrapper :global(.react-calendar__month-view__days__day--neighboringMonth) {
  color: #9ca3af;
}

.calendarWrapper :global(.react-calendar__tile) {
  padding: 10px 6.6667px;
  line-height: 16px;
}

.calendarWrapper :global(.react-calendar__tile:disabled) {
  color: #9ca3af;
}

.calendarWrapper :global(.react-calendar__tile:enabled:hover) {
  background-color: #e6e6e6;
}

.calendarWrapper :global(.react-calendar__tile:enabled:focus) {
  background-color: #e6e6e6;
}

.calendarWrapper :global(.react-calendar__tile--hasActive) {
  background: #581eeb;
  color: white;
}

.calendarWrapper :global(.react-calendar__tile--hasActive:enabled:hover) {
  background: #581eeb;
  color: white;
}

.calendarWrapper :global(.react-calendar__tile--hasActive:enabled:focus) {
  background: #581eeb;
  color: white;
}

.calendarWrapper :global(.react-calendar__tile--active) {
  background: #581eeb;
  color: white;
}

.calendarWrapper :global(.react-calendar__tile--active:enabled:hover) {
  background: #581eeb;
  color: white;
}

.calendarWrapper :global(.react-calendar__tile--active:enabled:focus) {
  background: #581eeb;
  color: white;
}

.calendarWrapper :global(.react-calendar__month-view__weekdays__weekday abbr) {
  text-decoration: none;
}
