.listItemChecked,
.listItemUnchecked {
  @apply relative ml-4 mr-4 pl-8 pr-8 list-none outline-none;
}
.listItemChecked {
  @apply line-through text-gray-500;
}
.listItemUnchecked:before,
.listItemChecked:before {
  @apply content-[''] w-4 h-4 top-[2px] left-0 cursor-pointer block bg-cover absolute;
}
.listItemUnchecked[dir='rtl']:before,
.listItemChecked[dir='rtl']:before {
  @apply left-auto right-0;
}
.listItemUnchecked:focus:before,
.listItemChecked:focus:before {
  @apply rounded-sm;
}
.listItemUnchecked:before {
  @apply border border-gray-300 rounded-sm;
}
.listItemChecked:before {
  @apply border border-gray-300 rounded-sm bg-gray-300 bg-no-repeat;
}
.listItemChecked:after {
  @apply content-[''] cursor-pointer border-white border-solid absolute block;
  top: 2px;
  width: 6px;
  left: 5px;
  right: 0;
  height: 12px;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
}
