* {
  scrollbar-width: thin;
  /* The first applies to the thumb of the scrollbar, the second to the track.  */
  /* gray-200 */ /* gray-50 */
  scrollbar-color: #e0e0e1 #f9f9f9;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  /* gray-200 */
  background-color: #e0e0e1;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  /* gray-50 */
  background-color: #f9f9f9;
}

html {
  font-size: 16px;
}

[data-lexical-editor='true'] ::selection {
  @apply bg-indigo-200;
}
[data-lexical-editor='true'] ::-moz-selection {
  @apply bg-indigo-200;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

input.no-spinner::-webkit-outer-spin-button,
input.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.no-spinner[type='number'] {
  -moz-appearance: textfield;
}

/* Give a remote user a caret */
.collaboration-cursor__caret {
  position: relative;
  margin-left: -1px;
  margin-right: -1px;
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  word-break: normal;
  pointer-events: none;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  position: absolute;
  top: -1.4em;
  left: -1px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  user-select: none;
  color: #0d0d0d;
  padding: 0.1rem 0.3rem;
  border-radius: 3px 3px 3px 0;
  white-space: nowrap;
}

.ProseMirror img.ProseMirror-selectednode {
  @apply ring-2 ring-offset-2 ring-indigo-800;
}

.formInput {
  @apply block w-full px-3 py-2 text-gray-900 placeholder-gray-400 transition-shadow duration-150 ease-in-out bg-white border-gray-200 rounded-md shadow-sm outline-none resize-none focus:outline-none focus:ring-indigo-800 focus:border-indigo-800;
}

.formInputFlat {
  @apply block w-full py-3 border-none resize-none placeholder:text-gray-400 focus:ring-0 focus:outline-none focus:bg-indigo-50;
}

.focusRing {
  @apply transition-all duration-150 ease-in-out outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-800;
}

/* OOTB Tippy renders with z-index 9999 - but we want it to be lower so it doesn't interfere with modals etc. */
[data-tippy-root] {
  z-index: 999 !important;
}
/* 
  Tippy needs .show-tippy-arrow class to show arrow as we do not import library's global style import 'tippy.js/dist/tippy.css'
  currently supports only data-placement^='bottom'
*/
.show-tippy-arrow > .tippy-arrow:before {
  content: '';
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.show-tippy-arrow.tippy-box[data-placement^='bottom'] > .tippy-svg-arrow {
  @apply fill-gray-800;
}
.show-tippy-arrow.tippy-box[data-placement^='bottom'] > .tippy-svg-arrow > svg {
  transform: scaleX(1.5) scaleY(2.7); /* width=24px, height=16px  */
  bottom: 21px;
}

.artImageCrop .ReactCrop__crop-selection {
  border: none;
}

@keyframes animationLoading {
  to {
    clip-path: inset(0 -0.4ch 0 0);
  }
}

.profile-dropdown-shadow {
  --tw-shadow: 2px -10px 15px -3px rgb(0 0 0 / 0.1), 1px -4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 2px -10px 15px -3px var(--tw-shadow-color), 1px -4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/* 
  Hide Zendesk widget on small screens
*/
@media screen and (max-width: 640px) {
  iframe[title*='Messaging window'i] {
    display: none;
  }
  iframe[title*='unread messages'i] {
    display: none;
  }
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(25%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
