html,
body {
  font-family: 'Diatype', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  /* Font Smoothing */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Diatype';
  src: url('https://cdn.jasper.ai/fonts/Diatype.woff2') format('woff2'),
    url('https://cdn.jasper.ai/fonts/Diatype.woff') format('woff');
  font-weight: 200 900;
  font-style: normal italic;
}
